import urlParams from "@/functions/urlParams"

const appName = process.env.VUE_APP_SITENAME
const appYear = process.env.VUE_APP_TAX_YEAR
const minActivePriorYear = appYear - 7
const currentDateYear = new Date().getFullYear()
const numberReturnsFiled = "63,000,000+"
let authSubdomain = process.env.VUE_APP_AUTH_SUBDOMAIN
let subdomain = process.env.VUE_APP_SUBDOMAIN
let env = process.env.VUE_APP_ENV_NAME

urlParams.saveUrlParams()

let params = urlParams.constructParams()

let paramsNoAmp
paramsNoAmp = "?" + params

let appBaseUrl = "https://" + authSubdomain + "." + appName + ".com"
//test code for nginx-proxy server
if (env === "dev") {
	appBaseUrl = "https://" + authSubdomain + "." + appName + ".localhost:8443"
}

let currentYearParam = "&appYear=" + appYear
let priorYearBaseUrl = appBaseUrl + "?appYear="
let priorYear2023Url = priorYearBaseUrl + "2023&NEWTAX" + params
let priorYear2022Url = priorYearBaseUrl + "2022&NEWTAX" + params
let priorYear2021Url = priorYearBaseUrl + "2021&NEWTAX" + params
let priorYear2020Url = priorYearBaseUrl + "2020&NEWTAX" + params
let priorYear2019Url = priorYearBaseUrl + "2019&NEWTAX" + params
let priorYear2018Url = priorYearBaseUrl + "2018&NEWTAX" + params
let priorYear2017Url = priorYearBaseUrl + "2017&NEWTAX" + params
let oldAppUrl = priorYearBaseUrl + (appYear - 1) + params
let newUrl = appBaseUrl + "?NEWTAX" + params + currentYearParam
let paidPreparerUrl = appBaseUrl + "?NEWTAX&PAIDPREPARER" + params + currentYearParam
let deluxeUrl = appBaseUrl + "?DLX&NEWTAX" + params + currentYearParam
let auditDefenseUrl = appBaseUrl + "?AD&NEWTAX" + params + currentYearParam
let continueUrl = appBaseUrl + paramsNoAmp + currentYearParam
let amendedUrl = appBaseUrl + "?AMENDED&goto_page=106" + params + currentYearParam
let checkUrl = appBaseUrl + "?goto_page=50" + params + currentYearParam
let proSupportUrl = appBaseUrl + "?NEWTAX&PROSUPPORT" + params + currentYearParam
let contactSupportDeluxeUrl = appBaseUrl + "?goto_page=7&" + params + currentYearParam
let contactSupportUrl = appBaseUrl + "?goto_page=7" + params + currentYearParam
let extUrl = appBaseUrl + "?NEWTAX&NEWEXT" + params + currentYearParam
let emailUrl = appBaseUrl + "?goto_page=60" + params + currentYearParam
let signInHelpUrl = appBaseUrl + "/ReclaimAccount" + "?" + params + currentYearParam
let creditUrl = appBaseUrl + "?goto_page=547" + params + currentYearParam
let printUrl = appBaseUrl + "?goto_page=100001" + params + currentYearParam
let downloadPath = "https://" + subdomain + "." + appName + ".com/forms/"

export const constants = {
	amendedUrl: amendedUrl,
	appName: appName,
	appYear: appYear,
	minActivePriorYear: minActivePriorYear,
	currentDateYear: currentDateYear,
	priorYear2023Url: priorYear2023Url,
	priorYear2022Url: priorYear2022Url,
	priorYear2021Url: priorYear2021Url,
	priorYear2020Url: priorYear2020Url,
	priorYear2019Url: priorYear2019Url,
	priorYear2018Url: priorYear2018Url,
	priorYear2017Url: priorYear2017Url,
	checkUrl: checkUrl,
	contactSupportDeluxeUrl: contactSupportDeluxeUrl,
	contactSupportUrl: contactSupportUrl,
	continueUrl: continueUrl,
	creditUrl: creditUrl,
	deluxeUrl: deluxeUrl,
	emailUrl: emailUrl,
	extUrl: extUrl,
	newUrl: newUrl,
	auditDefenseUrl: auditDefenseUrl,
	proSupportUrl: proSupportUrl,
	signInHelpUrl: signInHelpUrl,
	printUrl: printUrl,
	oldAppUrl: oldAppUrl,
	numberReturnsFiled: numberReturnsFiled,
	downloadPath: downloadPath,
	paidPreparerUrl: paidPreparerUrl
}
